import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ContentBox } from '@pokemon/design.ui.containers.content-box'
import { Mascots } from '@pokemon/design.ui.containers.background'
import { ErrorBoundary } from '@pokemon/design.ui.error.error-boundary'
import { ErrorFallback } from '@pokemon/design.ui.error.error-fallback'

import './App.css'
import { AppWrapper, Footer } from './template'
import config from './config'
import { loadReCAPTCHA } from './requests'
import Home from './recover_account'
import EmailForm from './email_form'
import SecondInputForm from './second_input_form'
import VerifyCode from './code_verification'
import PasswordInputForm from './password_input_form'
import RecoverySuccess from './successful_recovery'
import { LoadingSpinner } from '@pokemon/design.ui.loading-spinner'

function App () {
  // load the reCAPTCHA script if it hasn't been loaded yet... making useEffect only run it once is sort of unnecessary, but eh
  useEffect(
    () => {
      loadReCAPTCHA()
    }, []
  )

  const { t } = useTranslation()
  const urlParams = new URLSearchParams(window.location.search)

  const [errorText, setErrorText] = useState(undefined)

  const setError = (history, text) => {
    setErrorText(text)
    history.replace('/error')
  }

  const routeProps = {
    setError
  }

  // hey friend, why are you using a global?
  // well, the previous code that wired this value literally everywhere was really buggy
  // we could use a context, but contexts are pretty hard to use with class components
  // if the usage of login_challenge ever changes from "write once, read everywhere" then it probably shouldn't be a global anymore
  const challenge = urlParams.get('login_challenge')
  let errorLoginUrl
  if (challenge) {
    sessionStorage.setItem('login_challenge', challenge)
    errorLoginUrl = `${config.links.LOGIN_URL}?login_challenge=${challenge}`
  } else {
    errorLoginUrl = config.links.PCOM_URL
  }

  const WrappedErrorFallback = ({ text }) =>
    <AppWrapper>
      <ErrorFallback
        header={t('landing_screen_header', 'Recover Your Pokemon Trainer Account')}
        text={text}
        secondaryButton={{
          text: t('return_to_login', 'Return to Login'),
          url: errorLoginUrl
        }}
      />
    </AppWrapper>

  return (
    <Router>
      <ContentBox>
        <AppWrapper>
          <LoadingSpinner altText={t('pokemon_spinner', 'Loading')}>
            <ErrorBoundary fallback={<WrappedErrorFallback />}>
              <Switch>
                <Route path='/forgot-username/enter-email' render={(props) => <EmailForm method='username' {...{ ...props, ...routeProps }} />}/>
                <Route path='/forgot-username/enter-birth-date' render={(props) => <SecondInputForm {...props} />}/>
                <Route path='/forgot-password/enter-email' render={(props) => <EmailForm method='password' {...{ ...props, ...routeProps }} />}/>
                <Route path='/forgot-password/enter-username' render={(props) => <SecondInputForm {...props} />}/>
                <Route path='/error'>
                  <WrappedErrorFallback text={errorText} />
                </Route>
                <Route path='/code-verification' render={(props) => <VerifyCode {...{ ...props, ...routeProps }} />} />
                <Route path='/enter-password' render={(props) => <PasswordInputForm {...{ ...props, ...routeProps }} />} />
                <Route path='/username-recovered' render={(props) => <RecoverySuccess {...{ ...props, ...routeProps }} />} />
                <Route path='/password-reset' render={(props) => <RecoverySuccess {...{ ...props, ...routeProps }} />} />
                <Route path='/'>
                  <Home />
                </Route>
              </Switch>
            </ErrorBoundary>

          </LoadingSpinner>
          <Footer />
        </AppWrapper>

      </ContentBox>
      <Mascots />
    </Router>
  )
}

export default App
