import React from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { BasicButton } from '@pokemon/design.ui.input.basic-button'
import { ReturnButton } from './template'

function Home (props) {
  const history = useHistory()
  const { t } = useTranslation()
  return (
    <>
      <div className='title-wrapper'>
        <h1>
          {t('landing_screen_header', 'Recover Your Pokemon Trainer Account')}
        </h1>
      </div>
      <BasicButton buttonStatus={'secondary'} onClick={() => { history.push('/forgot-username/enter-email') }}
                    text={t('forgot_username', 'Forgot Username?')}>
      </BasicButton>
      <BasicButton buttonStatus={'secondary'} onClick={() => { history.push('/forgot-password/enter-email') }} text={t('forgot_password', 'Forgot Password?')}/>
      <ReturnButton />
    </>
  )
}

export default Home
