import React from 'react'
import { Redirect, withRouter } from 'react-router-dom'
import { withTranslation, Trans } from 'react-i18next'
import { verifyUser } from './requests'
import { BasicButton } from '@pokemon/design.ui.input.basic-button'
import { Field, Form } from 'react-final-form'
import BForm from 'react-bootstrap/Form'
import { TextField } from '@pokemon/design.ui.input.text-field'
import { LoadingContext } from '@pokemon/design.ui.loading-spinner'

const validateCode = t => (value) => {
  if (!(/^[0-9]{6}$/.test(value))) {
    return (
      <Trans
        t={t}
        i18nKey='invalid_code_error'
        defaults='The code you entered is invalid.'
      />
    )
  }
}

class VerifyCode extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  componentDidMount = () => {
    const { setLoading } = this.context

    if (this.props.location.state) {
      if (!this.props.location.state.codeError) {
        setLoading(true)
        const { email, secondPiece, secondPieceType } = this.props.location.state.accountInfo
        verifyUser(email, secondPiece, secondPieceType)
          .catch(() => {
            this.props.setError(this.props.history)
          }).finally(() => {
            setLoading(false)
          })
      } else {
        this.setState({
          codeError: this.props.location.state.codeError
        })
        setLoading(false)
      }
    }
  }

  handleSubmit = (value) => {
    this.setState({
      redirect: true,
      verificationCode: value.code
    })
  }

  render = () => {
    const { t, history } = this.props
    const redirect = (this.state) ? this.state.redirect : false
    if (redirect) {
      const path = (this.props.location.state.method === 'username') ? '/username-recovered' : '/enter-password'
      return (
        <Redirect to={{
          pathname: path,
          state: {
            method: this.props.location.state.method,
            accountInfo: this.props.location.state.accountInfo,
            verificationCode: this.state.verificationCode
          }
        }}
        />
      )
    } else if (!this.props.location.state) {
      return (
        <Redirect to={{ pathname: '/' }} />
      )
    } else {
      let recoveryBlurb

      if (this.props.location.state.method === 'username') {
        recoveryBlurb = <Trans
          t={t}
          i18nKey='username_code_sent_or_retry'
          defaults={'If your information was entered correctly, a verification code was sent to your inbox to recover your username. Haven\'t received it? <retryLink>Try re-entering your information.</retryLink>'}
          components={{ retryLink: <button style={{ margin: '0px', padding: '0px' }} className='link linklike-button' href="" onClick={() => history.replace('forgot-username/enter-email')}/> }}
        />
      } else {
        recoveryBlurb = <Trans
          t={t}
          i18nKey='password_code_sent_or_retry'
          defaults={'If your information was entered correctly, a verification code was sent to your inbox to reset your password. Haven\'t received it? <retryLink>Try re-entering your information.</retryLink>'}
          components={{ retryLink: <button style={{ margin: '0px', padding: '0px' }} className='link linklike-button' href="" onClick={() => history.replace('forgot-password/enter-email')}/> }}
        />
      }
      return (
        <>
          <div className='title-wrapper'>
            <h1>
              {t('header_enter_verification_code', 'Enter the Verification Code')}
            </h1>
            <label htmlFor="code" className='sub-title'>
              {recoveryBlurb}
            </label>
          </div>
          <Form
            onSubmit={this.handleSubmit}
            render = {
              ({ handleSubmit, submitting, valid }) => (
                <form className='recovery-info-form' onSubmit={handleSubmit}>
                  <Field
                    name="code"
                    validate={validateCode(t)}
                  >
                    {({ input, meta }) => (
                      <React.Fragment>
                        <TextField name='code' placeholder={t('verification_code', 'Verification Code')} {...input} />
                        {((meta.error && meta.touched) || this.state.codeError) && <BForm.Control.Feedback type="invalid">
                          <p style={{ marginTop: '0' }} className={'invalid-feedback'}>
                            {meta.error}
                          </p>
                        </BForm.Control.Feedback>}
                      </React.Fragment>
                    )}
                  </Field>
                  <BasicButton
                    className="continue-button"
                    type={'submit'}
                    buttonStatus={'primary'}
                    text={t('continue_button', 'Continue')}
                    disabled={submitting || !valid}
                    data-testid={'continue-button'}
                  />
                </form>
              )
            }
          />
        </>
      )
    }
  }
}
VerifyCode.contextType = LoadingContext

export default withRouter(withTranslation()(VerifyCode))
