import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import './i18n'
import App from './App'
import spinner from './assets/images/pokeball_gray.png'
import reportWebVitals from './reportWebVitals'
import { datadogRum } from '@datadog/browser-rum'
import { datadogLogs } from '@datadog/browser-logs'

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<img className='spinner' src={spinner} alt='...' />}>
      <App />
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
)

datadogRum.init({
  applicationId: 'b03bc4d3-84e8-4605-80af-2072cb750dac',
  clientToken: 'pub402afd535579db5cd17d6bc1226b789a',
  site: 'datadoghq.com',
  service: 'account-recovery-frontend',
  env: process.env.REACT_APP_STAGE,
  version: require('../package.json').version,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
  allowedTracingUrls: [
    (process.env.REACT_APP_ARAPI_BASE_URL) ? process.env.REACT_APP_ARAPI_BASE_URL : 'https://api.recover.alpha.pokemon.com'
  ]
})
datadogRum.startSessionReplayRecording()
datadogLogs.init({
  clientToken: 'pub402afd535579db5cd17d6bc1226b789a',
  site: 'datadoghq.com',
  silentMultipleInit: true,
  forwardErrorsToLogs: true,
  sampleRate: 100
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
