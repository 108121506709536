import React from 'react'
import { Redirect } from 'react-router-dom'
import { ReturnButton } from './template'
import { withTranslation, Trans } from 'react-i18next'
import { verifyCode } from './requests'
import { LoadingContext } from '@pokemon/design.ui.loading-spinner'

class RecoverySuccess extends React.Component {
  componentDidMount = () => {
    const { setLoading } = this.context
    if (this.props.location.state) {
      if (!this.props.location.state.resetPasswordSuccess) {
        setLoading(true)
        const code = this.props.location.state.verificationCode
        const { email, secondPiece } = this.props.location.state.accountInfo
        verifyCode(code, email, secondPiece, this.props.location.state.method)
          .then(codeVerified => {
            this.setState({
              codeVerified: codeVerified
            },
            () => setLoading(false)
            )
          })
      }
    }
  }

  render = () => {
    const t = this.props.t
    const codeVerified = (this.state) ? this.state.codeVerified : false
    // Kick users back to home page if they haven't progressed through flow
    if (!this.props.location.state) {
      return (
        <Redirect to={{ pathname: '/' }} />
      )
    } else if (this.props.location.state.resetPasswordSuccess) {
      return (
        <>
          <div className='title-wrapper'>
            <h1>
              {t('landing_screen_header', 'Recover Your Pokemon Trainer Account')}
            </h1>
            <p className='sub-title'>
              {t('password_reset_success', 'Thanks! Your password has been reset successfully.')}
            </p>
          </div>
          <ReturnButton />
        </>
      )
    } else if (codeVerified === 'err') {
      // Kick users back to code screen if their code is invalid
      return (
        <Redirect to={{
          pathname: '/code-verification',
          state: {
            method: this.props.location.state.method,
            accountInfo: this.props.location.state.accountInfo,
            codeError: true
          }
        }}
        />
      )
    } else if (codeVerified && this.props.location.state.method === 'username') {
      return (
        <>
          <div className='title-wrapper'>
            <h1>
              {t('landing_screen_header', 'Recover Your Pokemon Trainer Account')}
            </h1>
            <p className='sub-title'>
              { this.state.codeVerified.length === 1
                ? (<Trans
                  t={t}
                  i18nKey='username_recovered'
                  defaults='Your username is: <bold>{{username}}</bold>'
                  components={ { bold: <b/> } }
                  values={ { username: this.state.codeVerified[0] } }
              />)
              // when there are multiple usernames in an account
                : (<div>
                <Trans
                  t={t}
                  i18nKey='usernames_recovered'
                  defaults='Usernames associated with your account:' />
                <br/>
                <br/>
              {this.state.codeVerified.map(username =>
                <strong key={username}>{username}<br/></strong>
              )}
                </div>)}
            </p>
          </div>
          <ReturnButton />
        </>
      )
    } else {
      // this should only be visible for a single frame - if something else happens, this needs fixing
      return null
    }
  }
}
RecoverySuccess.contextType = LoadingContext

export default withTranslation()(RecoverySuccess)
