import React from 'react'
import { Form, Field } from 'react-final-form'
import BForm from 'react-bootstrap/Form'
import { useHistory } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import { BasicButton } from '@pokemon/design.ui.input.basic-button'
import * as EmailValidator from 'email-validator'
import { TextField } from '@pokemon/design.ui.input.text-field'

const validateEmail = t => (value) => {
  if (!EmailValidator.validate(value)) {
    return t('invalid_email_error', 'Please enter a valid email')
  }
}

const EmailForm = (props) => {
  const { t } = useTranslation()
  const history = useHistory()
  const pathname = (props.method === 'username') ? '/forgot-username/enter-birth-date' : '/forgot-password/enter-username'
  const accountDetail = (props.method === 'username') ? t('username', 'username') : t('password', 'password')
  return (
    <>
      <div className='title-wrapper'>
        <h1>
          <Trans
            t={t}
            i18nKey='forgot_account_header'
            defaults='Forgot your {{account_detail}}?'
            values={{ account_detail: accountDetail }}
          />
        </h1>
        <label className="sub-title" htmlFor="email">{t('account_email_request', 'Please enter the email address associated with your account')}</label>
      </div>
      <Form
        onSubmit={(values) => {
          history.push({
            pathname,
            state: {
              method: props.method,
              nextInput: (props.method === 'username') ? 'dob' : 'username',
              accountInfo: {
                email: values.email
              }
            }
          })
        }}
        render = {
          ({ handleSubmit, submitting, valid }) => (
            <form className='recovery-info-form' onSubmit={handleSubmit}>
              <Field
                name="email"
                validate={validateEmail(t)}
              >
                {({ input, meta }) => (
                  <React.Fragment>
                    <TextField
                      name="email"
                      placeholder={t('email_placeholder', 'Email')} {...input} />
                    {meta.error && meta.touched && <BForm.Control.Feedback type="invalid">{meta.error}</BForm.Control.Feedback>}
                  </React.Fragment>
                )}
              </Field>
              <button type="button" className='lost-email-link link linklike-button' href="" onClick={() => {
                props.setError(history, t('lost_email_account', 'If you no longer have access to the email address associated with this account, you will have to contact Customer Support.'))
              }}>
                {t('lost_account_link', 'I can\'t access my email account')}
              </button>
              <BasicButton
                className="continue-button"
                type={'submit'}
                buttonStatus={'primary'}
                text={t('continue_button', 'Continue')}
                disabled={submitting || !valid}
              />
            </form>
          )
        }
      />
    </>
  )
}

export default EmailForm
