import React from 'react'
import { Form, Field } from 'react-final-form'
import { Redirect } from 'react-router-dom'
import { withTranslation, Trans } from 'react-i18next'
import { BasicButton } from '@pokemon/design.ui.input.basic-button'
import { DatePicker } from '@pokemon/design.ui.input.date-picker'
import { TextField } from '@pokemon/design.ui.input.text-field'

class SecondInputForm extends React.Component {
  constructor (props) {
    super(props)

    const currDate = new Date()
    this.state = {
      currDate: currDate,
      selectedDate: {
        month: '',
        day: '',
        year: ''
      },
      username: '',
      error: false,
      redirect: false,
      valid: false
    }
    this.render = this.render.bind(this)
  }

    validateUsername = () => {
      return (/^[0-9a-zA-ZÀ-ÿ_+]{1,16}$/.test(this.state.username))
    }

    prependZero (date) {
      return ('0' + date).slice(-2)
    }

    handleSubmit = (event) => {
      if (this.props.location.state.nextInput === 'dob') {
        const dob = `${this.state.selectedDate.year}-${this.prependZero(this.state.selectedDate.month)}-${this.prependZero(this.state.selectedDate.day)}`
        this.setState({
          dob: dob,
          redirect: true
        })
      } else {
        if (this.validateUsername()) {
          this.setState({ redirect: true })
        } else {
          this.setState({ error: true })
        }
      }
    }

    handleDateChange = (event) => {
      this.setState({
        selectedDate: event,
        valid: !(event.day === '' || event.month === '' || event.year === '')
      })
    }

    handleUsernameChange = (event) => {
      this.setState({ username: event.target.value, valid: event.target.value !== '' })
    }

    secondInputField () {
      const t = this.props.t
      if (this.props.location.state.nextInput === 'dob') {
        return <DatePicker monthLabel={t('month', 'Month')}
                    dayLabel={t('day', 'Day')}
                    yearLabel={t('year', 'Year')}
                    months={[
                      t('month_january', 'January'),
                      t('month_february', 'February'),
                      t('month_march', 'March'),
                      t('month_april', 'April'),
                      t('month_may', 'May'),
                      t('month_june', 'June'),
                      t('month_july', 'July'),
                      t('month_august', 'August'),
                      t('month_september', 'September'),
                      t('month_october', 'October'),
                      t('month_november', 'November'),
                      t('month_december', 'December')
                    ]}
                    value={this.state.selectedDate}
                    onChange={this.handleDateChange}/>
      } else {
        return (
          <Field name="username">
            {({ input, meta }) => (
              <React.Fragment>
                <TextField
                  name="username"
                  placeholder={t('username_field', 'Username')}
                  onBlur={(event) => input.onBlur(event)}
                  onFocus={(event) => input.onFocus(event)}
                  onChange={(event) => this.handleUsernameChange(event)}
                />
                {meta.touched}
              </React.Fragment>
            )}
          </Field>
        )
      }
    }

    render = () => {
      const t = this.props.t
      // Check redirect first
      if (this.state.redirect) {
        return (
          <Redirect to={{
            pathname: '/code-verification',
            state: {
              method: this.props.location.state.method,
              accountInfo: {
                email: this.props.location.state.accountInfo.email,
                secondPiece: (this.props.location.state.nextInput === 'dob') ? this.state.dob : this.state.username,
                secondPieceType: (this.props.location.state.nextInput === 'dob') ? 'dob' : 'username'
              }
            }
          }}
          />
        )
      } else if (!this.props.location.state) {
        return (
          <Redirect to={{ pathname: '/' }} />
        )
      } else {
        const accountDetail = (this.props.location.state.method === 'username') ? t('username', 'username') : t('password', 'password')
        const nextInput = (this.props.location.state.nextInput === 'dob') ? t('birth_date', 'birth date') : t('username', 'username')
        return (
          <>
            <div className='title-wrapper'>
              <h1>
                <Trans
                  t={t}
                  i18nKey='forgot_account_header'
                  defaults='Forgot your {{account_detail}}?'
                  values={{ account_detail: accountDetail }}
                />
              </h1>
              <p className='sub-title'>
                <Trans
                  t={t}
                  i18nKey='account_detail_request'
                  defaults='Please enter the {{next_input}} associated with your account'
                  values={{ next_input: nextInput }}
                />
              </p>
            </div>
            <div className={this.state.error ? 'show-error' : 'hide-error'}>
              <p className='error-message' data-testid='error-message'>
                {t('invalid_username_error', 'Please enter a valid username')}
              </p>
            </div>
            <Form
              onSubmit={this.handleSubmit}
              render = {
                ({ handleSubmit, form, submitting }) => (
                  <form onSubmit={handleSubmit} className='recovery-info-form'>
                    {this.secondInputField()}
                    <BasicButton
                      className="continue-button"
                      type={'submit'}
                      buttonStatus={'primary'}
                      text={t('continue_button', 'Continue')}
                      disabled={!this.state.valid}
                      data-testid='continue-button'
                    />
                  </form>
                )
              }
            />
          </>
        )
      }
    }
}

export default withTranslation()(SecondInputForm)
