import config from './config'
import { load } from 'recaptcha-v3-enterprise'
import i18n from './i18n'

const arapiBaseURL = config.links.ARAPI_BASE_URL

async function loadReCAPTCHA () {
  return await load(config.RECAPTCHA_SITE_KEY, {
    autoHideBadge: true
  })
}

async function sendRequest (method, endpoint, data, attachedReCAPTCHAAction, validResponseCodes = [200, 204]) {
  const headers = { 'Content-Type': 'application/json', 'accept-language': i18n.language }
  const challenge = sessionStorage.getItem('login_challenge')
  if (challenge) {
    headers['x-login-challenge'] = challenge
  }

  if (attachedReCAPTCHAAction && data) {
    try {
      const recaptcha = await loadReCAPTCHA()
      const token = await recaptcha.execute(attachedReCAPTCHAAction)
      data.recaptcha_token = token
    } catch (e) {
      console.error('error contacting recaptcha', e)
    }
  }
  return await fetch(endpoint, {
    method: method,
    headers: headers,
    body: data && JSON.stringify(data)
  }
  )
    .then(response => {
      return response
    })
    .catch(error => {
      return error
    })
}

async function verifyUser (email, secondDetail, secondDetailType) {
  const verifyEndpoint = `${arapiBaseURL}/user/${secondDetailType}/verify`
  let verifyUserData
  let recaptchaActionType
  if (secondDetailType === 'dob') {
    recaptchaActionType = 'recovery_verify_dob'
    verifyUserData = {
      email: email,
      dob: secondDetail
    }
  } else {
    recaptchaActionType = 'recovery_verify_username'
    verifyUserData = {
      email: email,
      username: secondDetail
    }
  }
  return await sendRequest('POST', verifyEndpoint, verifyUserData, recaptchaActionType).then(response => {
    if (response.status === 204) {
      return true
    } else {
      throw new Error('unexpected status')
    }
  })
}

async function verifyCode (recoveryCode, email, secondDetail, method) {
  if (method === 'username') {
    const recoverEndpoint = `${arapiBaseURL}/user/recover`
    const verifyUserCode = {
      verification_code: recoveryCode,
      email: email,
      dob: secondDetail
    }
    return await sendRequest('PUT', recoverEndpoint, verifyUserCode)
      .then(response => {
        if (response.status === 200) {
          return response.json().then(responseBody => {
            return responseBody.usernames
          })
        } else {
          return 'err'
        }
      })
  } else {
    const recoverEndpoint = `${arapiBaseURL}/user/reset`
    const verifyUserCode = {
      verification_code: recoveryCode,
      email: email,
      username: secondDetail
    }
    return await sendRequest('PUT', recoverEndpoint, verifyUserCode)
      .then(response => {
        if (response.status === 204) {
          return true
        } else {
          return 'err'
        }
      })
  }
}

async function resetPassword (username, email, verificationCode, password) {
  const resetEndpoint = `${arapiBaseURL}/user/password`
  const resetPasswordData = {
    username: username,
    email: email,
    verification_code: verificationCode,
    password: password
  }

  return await sendRequest('PUT', resetEndpoint, resetPasswordData, undefined, [204, 409])
    .then(response => {
      if (response.status === 204) {
        return {
          success: true
        }
      } else if (response.status === 409) {
        return {
          tooRecent: true
        }
      } else {
        throw new Error('unexpected status')
      }
    })
}

export { loadReCAPTCHA, verifyUser, verifyCode, resetPassword }
