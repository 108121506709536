/* eslint jsx-a11y/label-has-associated-control: 1 */
import { useTranslation, Trans } from 'react-i18next'
import React from 'react'
import config from './config.js'
import { BasicButton } from '@pokemon/design.ui.input.basic-button'
import { localizeLink, countryCodeReplacementString } from '@tpci/i18next-ext/lib'
import i18n from './i18n'

const loginURL = config.links.LOGIN_URL
const pcomURL = config.links.PCOM_URL

function AppWrapper ({ children }) {
  return (
    <div className='App'>
      <div className='body-container'>
        <main className='body-wrapper'>
          { children }
        </main>
      </div>
    </div>
  )
}

const googlePrivacyLink = 'https://policies.google.com/privacy'
const googleTermsLink = 'https://policies.google.com/terms'
const env = process.env.REACT_APP_DOMAIN || 'pokemon.com'
const pokemonLink = 'https://www.' + env

function Footer () {
  const { t } = useTranslation()
  const currYear = new Date().getFullYear()

  return (
      <footer className='footer-wrapper'>
        <span className='copyright'>
          <span style={{ display: 'block', marginBottom: '10px' }}>
            <Trans
              t={t}
              i18nKey='copyright'
              defaults='©{{curr_year}} Pokémon.\n©1995 - {{curr_year}} Nintendo/Creatures Inc./GAME FREAK inc. TM, ®Nintendo.'
              values={{ curr_year: currYear }}
            />
          </span>
          <span>
            <Trans
              t={t}
              i18nKey="recaptcha_terms_and_privacy"
              defaults="This site is protected by reCAPTCHA and the Google <googlePrivacyPolicyLink>Privacy Policy</googlePrivacyPolicyLink> and  <googleTermsLink>Terms of Service</googleTermsLink> apply. For more on how The Pokémon Company International shares information with third parties, please see our <pokemonPrivacyLink>Privacy Notice</pokemonPrivacyLink>."
              components={{
                googlePrivacyPolicyLink: <a className='link' href={googlePrivacyLink}></a>,
                googleTermsLink: <a className='link' href={googleTermsLink}></a>,
                pokemonPrivacyLink: <a className='link' href={localizeLink(i18n.language, pokemonLink + '/' + countryCodeReplacementString + '/privacy-notice/')}></a>
              }}
            />
          </span>
        </span>
      </footer>
  )
}

function ReturnButton (props) {
  const { t } = useTranslation()
  let returnUrl
  if (sessionStorage.getItem('login_challenge') == null) {
    returnUrl = pcomURL
  } else {
    returnUrl = loginURL + `?login_challenge=${sessionStorage.getItem('login_challenge')}`
  }
  return (
     <BasicButton style={{ marginTop: 30, width: '100%', ...props.style }} type={'link'} buttonStatus={'secondary'} href={returnUrl} text={t('return_to_login', 'Return to Login')}/>
  )
}

export { AppWrapper, Footer, ReturnButton }
