import React from 'react'
import { Redirect, withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { resetPassword, verifyCode } from './requests'
import { BasicButton } from '@pokemon/design.ui.input.basic-button'
import { Field, Form } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import { PasswordField, validatePassword } from '@pokemon/design.ui.input.password-field'
import { LoadingContext } from '@pokemon/design.ui.loading-spinner'

class PasswordInputForm extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  componentDidMount = () => {
    const { setLoading } = this.context
    if (this.props.location.state) {
      setLoading(true)
      const code = this.props.location.state.verificationCode
      const { email, secondPiece } = this.props.location.state.accountInfo
      verifyCode(code, email, secondPiece, this.props.location.state.method)
        .then(codeVerified => {
          this.setState({
            codeVerified: codeVerified,
            showNewPassword: false
          },
          () => setLoading(false)
          )
        })
    }
  }

  handleSubmit = (values, _, callback) => {
    const { setLoading } = this.context
    // Double check password validity
    setLoading(true)
    const code = this.props.location.state.verificationCode
    const { email, secondPiece } = this.props.location.state.accountInfo

    resetPassword(secondPiece, email, code, values.password)
      .then(resetResponse => {
        if (resetResponse.success) {
          this.setState(
            {
              resetPasswordSuccess: true
            },
            () => {
              callback()
            }
          )
        } else if (resetResponse.tooRecent) {
          callback({
            [FORM_ERROR]: this.props.t('password_error_recently_used', 'The password you entered has been used before')
          })
        }
      }).catch((err) => {
        console.error('Error resetting password', err)
        this.props.setError(this.props.history)
      }).finally(() => {
        setLoading(false)
      })
  }

  render = () => {
    const t = this.props.t
    const codeVerified = (this.state) ? this.state.codeVerified : false
    // Kick users back to home page if they haven't progressed through flow
    if (!this.props.location.state) {
      return (
        <Redirect to={{ pathname: '/' }} />
      )
    } else if (codeVerified === 'err') {
      // Kick users back to code screen if their code is invalid
      return (
        <Redirect to={{
          pathname: '/code-verification',
          state: {
            method: this.props.location.state.method,
            accountInfo: this.props.location.state.accountInfo,
            codeError: true
          }
        }}
        />
      )
    } else if (this.state?.resetPasswordSuccess) {
      return (
        <Redirect to={{
          pathname: '/password-reset',
          state: {
            method: this.props.location.state.method,
            accountInfo: this.props.location.state.accountInfo,
            resetPasswordSuccess: true
          }
        }}
        />
      )
    } else if (codeVerified && this.props.location.state.method === 'password') {
      return (
        <>
          <div className='title-wrapper' style={{ marginBottom: '20px' }}>
            <h1>
              {t('enter_new_password', 'Please enter a new password.')}
            </h1>
          </div>
          <Form
            onSubmit={this.handleSubmit}
            render = {
              ({ handleSubmit, submitting, hasSubmitErrors, hasValidationErrors, submitError, modifiedSinceLastSubmit }) => (
                <form className='recovery-info-form' onSubmit={handleSubmit} autoComplete={'new-password'} >
                  <Field
                    name="password"
                    validate={text => !validatePassword(text)}
                  >
                    {({ input }) => (
                      <React.Fragment>
                        <PasswordField
                            lengthText={t('password_requirement_length', '8 to 50 characters')}
                            uppercaseText={t('password_requirement_uppercase', 'One uppercase letter')}
                            lowercaseText={t('password_requirement_lowercase', 'One lowercase letter')}
                            specialCharText={t('password_requirement_special_char', 'One special character')}
                            numberText={t('password_requirement_number', 'One number')}
                            errorText={!modifiedSinceLastSubmit && submitError}
                            input={input}
                        />
                      </React.Fragment>
                    )}
                  </Field>
                  <p
                    style={{
                      fontFamily: 'Flexo-Bold, serif',
                      alignSelf: 'start',
                      paddingTop: '8px'
                    }}
                  >
                    {t('password_requirement_recently_used', 'To help protect your account, please be sure that your password is unique and not the same password that you use on any other site or app.')}
                  </p>
                  <BasicButton
                    className="continue-button"
                    data-testid='continue-button'
                    type={'submit'}
                    buttonStatus={'primary'}
                    text={t('continue_button', 'Continue')}
                    disabled={submitting || hasValidationErrors || (hasSubmitErrors && !modifiedSinceLastSubmit)}
                  />
                </form>
              )
            }
          />
        </>
      )
    } else {
      // this should only be visible for a single frame - if something else happens, this needs fixing
      return null
    }
  }
}
PasswordInputForm.contextType = LoadingContext

export default withRouter(withTranslation()(PasswordInputForm))
