const overridesByStage = {
  live: {
    RECAPTCHA_SITE_KEY: '6Lc1tnAfAAAAAJR-jXXMlEa5j1xZws9wQzF7knDK' // live account recovery
  }
}

export function buildConfig (stage, domain) {
  return {
    // Add common config values here
    links: {
      ARAPI_BASE_URL: `https://api.recover.${domain}`,
      LOGIN_URL: `https://access.${domain}/login`,
      PCOM_URL: `https://www.${domain}`
    },
    RECAPTCHA_SITE_KEY: '6LewQm8fAAAAAIVOrJKRe9J12mzHYwhLxldg1-Vx', // always pass
    ...(overridesByStage[stage] ?? {})
  }
}

export default buildConfig(process.env.REACT_APP_STAGE, process.env.REACT_APP_DOMAIN)
